
import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, ListGroup } from 'react-bootstrap'
import Select from 'react-select'
import { useLocation } from '../../hooks/location'
import IconAngleRight from '../../images/icon_angle-right.svg'
import { Address } from '../../types/address'
import { Order } from '../../types/order'
import Map from '../Map'
import { PageName } from './CreateOrder'
import { CARD_ADDRESS_LIST_COUNT, ListItem } from './SenderAddress'
import { ZipAddressResults } from '../../types/zipAddress'
import axios from 'axios'

type Props = {
  addresses: Address[]
  order: Order
  setOrder: React.Dispatch<React.SetStateAction<Order>>
  setPageName: React.Dispatch<React.SetStateAction<PageName>>
  transitionPageWithVerifyArea: (pageName: PageName) => Promise<void>
}

const ReceiverAddress: React.FC<Props> = ({
  addresses,
  order,
  setOrder,
  setPageName,
  transitionPageWithVerifyArea,
}) => {
  const {
    receiver_state: state,
    receiver_city: city,
    receiver_house_number: house_number,
    receiver_lat: lat,
    receiver_lng: lng,
    receiver_address_id: address_id,
  } = order
  const [value, setValue] = useState(null)
  const [location, getLocation] = useLocation({ lat, lng })

  const selectedAddress = useRef<Address>(null)
  const hasBeforeChangedLocation = useRef(false)

  const selectReceiverAddress = (order: Order, receiverAddress: Address) => {
    selectedAddress.current = receiverAddress
    setOrder({
      ...order,
      receiver_zipcode: receiverAddress.zipcode,
      receiver_city: receiverAddress.city,
      receiver_state: receiverAddress.state,
      receiver_house_number: receiverAddress.house_number,
      receiver_building_name: receiverAddress.building_name,
      receiver_email: receiverAddress.email,
      receiver_tel: receiverAddress.tel,
      receiver_name: receiverAddress.name,
      receiver_lat: receiverAddress.lat,
      receiver_lng: receiverAddress.lng,
      receiver_address_id: receiverAddress.id,
      save_receiver_address: false,
    })
  }

  const [isZipcodeInputted, setIsZipcodeInputted] = useState(false)

  useEffect(() => {
    if (order.receiver_zipcode && order.receiver_zipcode.match(/\d{7}/)) {
      const fetchData = async () => {
        try {
          const result = await axios.get<ZipAddressResults>(
            `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${order.receiver_zipcode}`
          );

          if (result.data.results) {
            const resultZipAddress = result.data.results[0]
            const resultData = {
              ...order,
              receiver_state: resultZipAddress.address1,
              receiver_city: resultZipAddress.address2 + resultZipAddress.address3,
              receiver_house_number: isZipcodeInputted ? '' : order.receiver_house_number,
              receiver_building_name: isZipcodeInputted ? '' : order.receiver_building_name,
              receiver_email: isZipcodeInputted ? '' : order.receiver_email,
              receiver_tel: isZipcodeInputted ? '' : order.receiver_tel,
              receiver_name: isZipcodeInputted ? '' : order.receiver_name
            }
            setIsZipcodeInputted(false)
            setOrder({ ...order, ...resultData })
          } else {
            alert('郵便番号に該当する住所が見つかりませんでした。\n郵便番号を使用する場合は再度入力してください。')
            const resetData = {
              ...order,
              receiver_state: '',
              receiver_city: '',
              receiver_house_number: '',
              receiver_building_name: '',
              receiver_email: '',
              receiver_tel: '',
              receiver_name: ''
            }
            setOrder({ ...order, ...resetData })
          }
          console.log('zipcode.:', result.status);
        } catch (e) {
          console.log(e)
        }
      };
      fetchData()
    }
  }, [order.receiver_zipcode])
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!lat && !lng) {
      return
    }

    hasBeforeChangedLocation.current = true
  }, [])

  useEffect(() => {
    if (!location.lat && !location.lng) {
      return
    }

    setOrder({
      ...order,
      receiver_lat: location.lat,
      receiver_lng: location.lng,
    })
  }, [location])

  useEffect(() => {
    if (hasBeforeChangedLocation.current) {
      hasBeforeChangedLocation.current = false
      return
    }

    if (!!selectedAddress.current?.lat && !!selectedAddress.current?.lng) {
      if (
        selectedAddress.current.lat === order.receiver_lat &&
        selectedAddress.current.lng === order.receiver_lng
      ) {
        selectedAddress.current = null
        return
      }
    }

    if (!state || !city || !house_number) {
      return
    }

    const address = state + city + house_number
    getLocation(address)

    const cleanup = () => {}
    return () => {
      cleanup()
    }
  }, [state, city, house_number])

  const validInput = () => {
    // TODO: 入力形式のバリデーション
    if (
      !order.receiver_state ||
      !order.receiver_city ||
      !order.receiver_house_number ||
      !order.receiver_name ||
      !order.receiver_tel ||
      !order.receiver_lat ||
      !order.receiver_lng
    ) {
      return false
    }
    return true
  }
  const options = addresses.slice(CARD_ADDRESS_LIST_COUNT).map((address) => {
    return {
      value: address.id,
      label: `${address.name} ${address.state}${address.city}${address.house_number} ${address.building_name}`,
    }
  })

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <a
            href={''}
            onClick={(e) => {
              e.preventDefault()
              if (validInput()) {
                setPageName('SenderAddress')
              }
            }}
          >
            <div className="d-flex align-items-center">
              {/* <div className="me-2">
                <img src={senderIconUrl} />
              </div> */}
              <div className="my-1">
                <div>集荷お伺い先</div>
              </div>
            </div>
          </a>
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <img src={IconAngleRight} />
        </div>
        <div className="me-5 pb-5 border-bottom border-primary border-4">
          <a
            href={''}
            onClick={(e) => {
              e.preventDefault()
            }}
          >
            <div className="d-flex align-items-center">
              {/* <div className="me-2">
                <img src={receiverIconUrl} />
              </div> */}
              <div className="my-1">
                <div className="text-primary">お届け先</div>
              </div>
            </div>
          </a>
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <img src={IconAngleRight} />
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <button
            form="receiverAddress"
            type="submit"
            className="btn text-secondary p-0"
          >
            <div className="my-1">お荷物情報</div>
          </button>
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <img src={IconAngleRight} />
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <a
            href={''}
            onClick={(e) => {
              e.preventDefault()
              if (
                validInput() &&
                order.pickup_at &&
                order.payment_method === 'invoice'
                  ? true
                  : order.card
                  ? true
                  : false
              ) {
                transitionPageWithVerifyArea('Confirmation')
              }
            }}
          >
            <div className="my-1">確認</div>
          </a>
        </div>
      </div>
      <div className="mb-6 border-top"></div>
      <div className="h6 mb-4">登録済みの住所から選ぶ</div>
      <div className="mb-6">
        <ListGroup horizontal className="overflow-auto">
          {addresses.slice(0, CARD_ADDRESS_LIST_COUNT).map((address, index) => {
            return (
              <ListItem
                className={`${index !== 0 ? 'ms-5' : ''} ${
                  address.id === address_id ? 'border-primary' : ''
                }`}
                key={address.id}
                onClick={() => {
                  selectReceiverAddress(order, address)
                }}
              >
                <div>{address.state + address.city + address.house_number}</div>
                <div>{address.building_name}</div>
                <div>{address.name}</div>
              </ListItem>
            )
          })}
        </ListGroup>
        {options.length > 0 && (
          <div className="mt-4">
            <Select
              placeholder="その他の住所を検索または選択"
              options={options}
              value={value}
              onChange={(e) => {
                e?.value ? setValue(e) : setValue(null)
                selectReceiverAddress(
                  order,
                  addresses.find((a) => a.id === e.value)
                )
              }}
            />
          </div>
        )}
      </div>
      <div className="mb-6 border-top" />
      <div className="h6 mb-5">住所を入力する</div>
      <Form
        id="receiverAddress"
        onSubmit={(event) => {
          transitionPageWithVerifyArea('Item')
          event.preventDefault()
          event.stopPropagation()
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
      >
        <Form.Group className="mb-5">
          <Form.Label>
            郵便番号
            <span className="tiny ms-1 text-gray">(郵便番号は任意です)</span>
          </Form.Label>
          <Form.Control
            minLength={7}
            maxLength={7}
            type="input"
            value={order.receiver_zipcode}
            onChange={(event) => {
              const value = parseInt(event.target.value) || ''
              setOrder({ ...order, receiver_zipcode: value.toString() })
              setIsZipcodeInputted(true)
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>
            都道府県<span className="tiny ms-1 text-danger">(必須)</span>
          </Form.Label>
          <Form.Control
            required
            type="input"
            value={order.receiver_state}
            onChange={(event) => {
              setOrder({ ...order, receiver_state: event.target.value })
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>
            市区町村<span className="tiny ms-1 text-danger">(必須)</span>
          </Form.Label>
          <Form.Control
            required
            type="input"
            value={order.receiver_city}
            onChange={(event) => {
              setOrder({ ...order, receiver_city: event.target.value })
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>
            丁目・番地<span className="tiny ms-1 text-danger">(必須)</span>
          </Form.Label>
          <Form.Control
            required
            type="input"
            value={order.receiver_house_number}
            onChange={(event) => {
              setOrder({ ...order, receiver_house_number: event.target.value })
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>建物名・部屋番号・その他</Form.Label>
          <Form.Control
            type="input"
            value={order.receiver_building_name}
            onChange={(event) => {
              setOrder({ ...order, receiver_building_name: event.target.value })
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>
            氏名<span className="tiny ms-1 text-danger">(必須)</span>
          </Form.Label>
          <Form.Control
            required
            type="input"
            value={order.receiver_name}
            onChange={(event) => {
              setOrder({ ...order, receiver_name: event.target.value })
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>
            電話番号<span className="tiny ms-1 text-danger">(必須)</span>
          </Form.Label>
          <Form.Control
            required
            minLength={10}
            maxLength={11}
            type="input"
            value={order.receiver_tel}
            pattern="^(0{1}\d{9,10})$"
            onChange={(event) => {
              setOrder({ ...order, receiver_tel: event.target.value })
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>email</Form.Label>
          <Form.Control
            type="email"
            value={order.receiver_email}
            pattern="^[a-zA-Z0-9.!#$&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
            onChange={(event) => {
              setOrder({ ...order, receiver_email: event.target.value })
            }}
          />
        </Form.Group>
        {!!lat && !!lng && (
          <div className="mb-5">
            <Map
              position={{ lat, lng }}
              onDragEnd={(e: google.maps.MapMouseEvent): void => {
                const lat = e.latLng.lat()
                const lng = e.latLng.lng()
                setOrder({ ...order, receiver_lat: lat, receiver_lng: lng })
              }}
            />
          </div>
        )}
        <Form.Group className="mb-5" controlId="saveAddressCheckbox">
          <Form.Check
            type="checkbox"
            label="この住所を保存する"
            checked={order.save_receiver_address}
            onChange={() => {
              setOrder({
                ...order,
                save_receiver_address: !order.save_receiver_address,
                receiver_address_id: order.save_receiver_address
                  ? address_id
                  : undefined,
              })
            }}
          />
        </Form.Group>
        <Button variant="secondary" type="submit">
          次へ
        </Button>
      </Form>
    </>
  )
}

export default ReceiverAddress
