
import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, ListGroup } from 'react-bootstrap'
import Select from 'react-select'
import { useLocation } from '../../hooks/location'
import IconAngleRight from '../../images/icon_angle-right.svg'
import { Address } from '../../types/address'
import { Order } from '../../types/order'
import Map from '../Map'
import { PageName } from './CreateOrder'
import { ZipAddressResults } from '../../types/zipAddress'
import axios from 'axios'

type Props = {
  addresses: Address[]
  order: Order
  setOrder: React.Dispatch<React.SetStateAction<Order>>
  setPageName: React.Dispatch<React.SetStateAction<PageName>>
  transitionPageWithVerifyArea: (pageName: PageName) => Promise<void>
  defaultSenderAddressId: string
}

export const CARD_ADDRESS_LIST_COUNT = 4

const SenderAddress: React.FC<Props> = ({
  addresses,
  order,
  setOrder,
  setPageName,
  transitionPageWithVerifyArea,
  defaultSenderAddressId,
}) => {
  const [value, setValue] = useState(null)
  const defaultAddress = addresses.find(
    (address) => address.id === defaultSenderAddressId
  )
  // NOTE: デフォルトがあれば先頭に持ってくる
  const addressList = !!defaultAddress
    ? [
        defaultAddress,
        ...addresses.filter((address) => address.id !== defaultSenderAddressId),
      ]
    : addresses
  const options = addressList.slice(CARD_ADDRESS_LIST_COUNT).map((address) => {
    return {
      value: address.id,
      label: `${address.name} ${address.state}${address.city}${address.house_number} ${address.building_name}`,
    }
  })
  const {
    sender_state: state,
    sender_city: city,
    sender_house_number: house_number,
    sender_lat: lat,
    sender_lng: lng,
    sender_address_id: address_id,
  } = order
  const [location, getLocation] = useLocation({ lat, lng })

  const selectedAddress = useRef<Address>(null)
  const hasBeforeChangedLocation = useRef(false)

  const selectSenderAddress = (order: Order, senderAddress: Address) => {
    selectedAddress.current = senderAddress
    setOrder({
      ...order,
      sender_zipcode: senderAddress.zipcode,
      sender_city: senderAddress.city,
      sender_state: senderAddress.state,
      sender_house_number: senderAddress.house_number,
      sender_building_name: senderAddress.building_name,
      sender_email: senderAddress.email,
      sender_tel: senderAddress.tel,
      sender_name: senderAddress.name,
      sender_lat: senderAddress.lat,
      sender_lng: senderAddress.lng,
      sender_address_id: senderAddress.id,
      save_sender_address: false,
      save_as_default_sender_address: false,
    })
  }

  const [isZipcodeInputted, setIsZipcodeInputted] = useState(false)

  useEffect(() => {
    if (order.sender_zipcode && order.sender_zipcode.match(/\d{7}/)) {
      const fetchData = async () => {
        try {
          const result = await axios.get<ZipAddressResults>(
            `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${order.sender_zipcode}`
          );

          if (result.data.results) {
            const resultZipAddress = result.data.results[0]
            const resultData = {
              ...order,
              sender_state: resultZipAddress.address1,
              sender_city: resultZipAddress.address2 + resultZipAddress.address3,
              sender_house_number: isZipcodeInputted ? '' : order.sender_house_number,
              sender_building_name: isZipcodeInputted ? '' : order.sender_building_name,
              sender_email: isZipcodeInputted ? '' : order.sender_email,
              sender_tel: isZipcodeInputted ? '' : order.sender_tel,
              sender_name: isZipcodeInputted ? '' : order.sender_name
            }
            setIsZipcodeInputted(false)
            setOrder({ ...order, ...resultData })
          } else {
            alert('郵便番号に該当する住所が見つかりませんでした。\n郵便番号を使用する場合は再度入力してください。')
            const resetData = {
              ...order,
              sender_state: '',
              sender_city: '',
              sender_house_number: '',
              sender_building_name: '',
              sender_email: '',
              sender_tel: '',
              sender_name: ''
            }
            setOrder({ ...order, ...resetData })
          }
          console.log('zipcode.:', result.status);
        } catch (e) {
          console.log(e)
        }
      };
      fetchData()
    }
  }, [order.sender_zipcode])

  useEffect(() => {
    if (!defaultAddress || order.sender_name) {
      return
    }

    selectSenderAddress(order, defaultAddress)
  }, [defaultAddress])

  useEffect(() => {
    if (!lat && !lng) {
      return
    }

    hasBeforeChangedLocation.current = true
  }, [])

  useEffect(() => {
    if (!location.lat && !location.lng) {
      return
    }

    setOrder({ ...order, sender_lat: location.lat, sender_lng: location.lng })
  }, [location])

  // TODO: この辺のロジックを共通化する
  useEffect(() => {
    // NOTE: 初回レンダー時は緯度経度を取得しない
    if (hasBeforeChangedLocation.current) {
      hasBeforeChangedLocation.current = false
      return
    }

    // NOTE: 選択された住所に緯度経度が存在する場合は、緯度経度を再取得しないようにする
    if (!!selectedAddress.current?.lat && !!selectedAddress.current?.lng) {
      if (
        selectedAddress.current.lat === order.sender_lat &&
        selectedAddress.current.lng === order.sender_lng
      ) {
        selectedAddress.current = null
        return
      }
    }

    if (!state || !city || !house_number) {
      return
    }

    const address = state + city + house_number
    getLocation(address)

    const cleanup = () => {}
    return () => {
      cleanup()
    }
  }, [state, city, house_number])

  const validInput = () => {
    // TODO: 入力形式のバリデーション
    if (
      !order.sender_state ||
      !order.sender_city ||
      !order.sender_house_number ||
      !order.sender_name ||
      !order.sender_tel ||
      !order.sender_lat ||
      !order.sender_lng
    ) {
      return false
    }
    return true
  }
  console.log('senderAddressId: ', order.sender_address_id)

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="me-5 pb-5 border-bottom border-primary border-4">
          <a
            href={''}
            onClick={(e) => {
              e.preventDefault()
            }}
          >
            <div className="d-flex align-items-center">
              {/* <div className="me-2">
                <img src={senderIconUrl} />
              </div> */}
              <div className="my-1">
                <div className="text-primary">集荷お伺い先</div>
              </div>
            </div>
          </a>
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <img src={IconAngleRight} />
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <button
            form="senderAddress"
            type="submit"
            className="btn text-secondary p-0"
          >
            <div className="my-1">
              <div>お届け先</div>
            </div>
          </button>
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <img src={IconAngleRight} />
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <a
            href={''}
            onClick={(e) => {
              e.preventDefault()
              if (validInput()) {
                transitionPageWithVerifyArea('Item')
              }
            }}
          >
            <div className="my-1">お荷物情報</div>
          </a>
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <img src={IconAngleRight} />
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <a
            href={''}
            onClick={(e) => {
              e.preventDefault()
              if (
                validInput() &&
                order.pickup_at &&
                order.payment_method === 'invoice'
                  ? true
                  : order.card
                  ? true
                  : false
              ) {
                transitionPageWithVerifyArea('Confirmation')
              }
            }}
          >
            <div className="my-1">確認</div>
          </a>
        </div>
      </div>
      <div className="mb-6 border-top" />
      <div className="h6 mb-4">登録済みの住所から選ぶ</div>
      <div className="mb-6">
        <ListGroup horizontal className="overflow-auto">
          {addressList
            .slice(0, CARD_ADDRESS_LIST_COUNT)
            .map((address, index) => {
              return (
                <ListItem
                  className={`${index !== 0 ? 'ms-5' : ''} ${
                    address.id === address_id ? 'border-primary' : ''
                  }`}
                  key={address.id}
                  onClick={() => {
                    selectSenderAddress(order, address)
                    setValue(null)
                  }}
                >
                  {defaultSenderAddressId &&
                    address.id === defaultSenderAddressId && (
                      <BadgePrimary>デフォルト</BadgePrimary>
                    )}
                  <div>
                    {address.state + address.city + address.house_number}
                  </div>
                  <div>{address.building_name}</div>
                  <div>{address.name}</div>
                </ListItem>
              )
            })}
        </ListGroup>
        {options.length > 0 && (
          <div className="mt-4">
            <Select
              placeholder="その他の住所を検索または選択"
              options={options}
              value={value}
              onChange={(e) => {
                e?.value ? setValue(e) : setValue(null)
                selectSenderAddress(
                  order,
                  addressList.find((a) => a.id === e.value)
                )
              }}
            />
          </div>
        )}
      </div>
      <div className="mb-6 border-top" />
      <div className="h6 mb-5">住所を入力する</div>
      <Form
        id="senderAddress"
        onSubmit={(event) => {
          setPageName('ReceiverAddress')
          event.preventDefault()
          event.stopPropagation()
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
              event.preventDefault();
          }
        }}
      >
        <Form.Group className="mb-5">
          <Form.Label>
            郵便番号
            <span className="tiny ms-1 text-gray">(郵便番号は任意です)</span>
          </Form.Label>
          <Form.Control
            minLength={7}
            maxLength={7}
            type="input"
            value={order.sender_zipcode}
            onChange={(event) => {
              const value = parseInt(event.target.value) || ''
              setOrder({ ...order, sender_zipcode: value.toString() })
              setIsZipcodeInputted(true)
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>
            都道府県<span className="tiny ms-1 text-danger">(必須)</span>
          </Form.Label>
          <Form.Control
            required
            type="input"
            value={order.sender_state}
            onChange={(event) => {
              setOrder({ ...order, sender_state: event.target.value })
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>
            市区町村<span className="tiny ms-1 text-danger">(必須)</span>
          </Form.Label>
          <Form.Control
            required
            type="input"
            value={order.sender_city}
            onChange={(event) => {
              setOrder({ ...order, sender_city: event.target.value })
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>
            丁目・番地<span className="tiny ms-1 text-danger">(必須)</span>
          </Form.Label>
          <Form.Control
            required
            type="input"
            value={order.sender_house_number}
            onChange={(event) => {
              setOrder({ ...order, sender_house_number: event.target.value })
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>建物名・部屋番号・その他</Form.Label>
          <Form.Control
            type="input"
            value={order.sender_building_name}
            onChange={(event) => {
              setOrder({ ...order, sender_building_name: event.target.value })
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>
            氏名<span className="tiny ms-1 text-danger">(必須)</span>
          </Form.Label>
          <Form.Control
            required
            type="input"
            value={order.sender_name}
            onChange={(event) => {
              setOrder({ ...order, sender_name: event.target.value })
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>
            電話番号<span className="tiny ms-1 text-danger">(必須)</span>
          </Form.Label>
          <Form.Control
            required
            minLength={10}
            maxLength={11}
            type="input"
            value={order.sender_tel}
            pattern="^(0{1}\d{9,10})$"
            onChange={(event) => {
              setOrder({ ...order, sender_tel: event.target.value })
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>email</Form.Label>
          <Form.Control
            type="email"
            value={order.sender_email}
            pattern="^[a-zA-Z0-9.!#$&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
            onChange={(event) => {
              setOrder({ ...order, sender_email: event.target.value })
            }}
          />
        </Form.Group>
        {!!order.sender_lat && !!order.sender_lng && (
          <div className="mb-5">
            <Map
              position={{ lat, lng }}
              onDragEnd={(e: google.maps.MapMouseEvent): void => {
                const lat = e.latLng.lat()
                const lng = e.latLng.lng()
                setOrder({ ...order, sender_lat: lat, sender_lng: lng })
              }}
            />
          </div>
        )}
        <Form.Group className="mb-5" controlId="saveAddressCheckbox">
          <Form.Check
            type="checkbox"
            label="この住所を保存する"
            checked={order.save_sender_address}
            onChange={() => {
              setOrder((prevOrder) => ({
                ...prevOrder,
                save_sender_address: !prevOrder.save_sender_address,
                save_as_default_sender_address:
                  prevOrder.save_as_default_sender_address &&
                  !prevOrder.save_sender_address,
                sender_address_id: prevOrder.save_sender_address
                  ? address_id
                  : undefined,
              }))
            }}
          />
        </Form.Group>
        <Form.Group className="mb-5" controlId="saveAsDefaultAddressCheckbox">
          <Form.Check
            type="checkbox"
            label="デフォルト住所として設定する"
            checked={order.save_as_default_sender_address}
            onChange={() => {
              setOrder((prevOrder) => ({
                ...prevOrder,
                save_sender_address: !prevOrder.save_as_default_sender_address,
                save_as_default_sender_address:
                  !prevOrder.save_as_default_sender_address,
                sender_address_id: prevOrder.save_as_default_sender_address
                  ? address_id
                  : undefined,
              }))
            }}
          />
        </Form.Group>
        <Button variant="secondary" type="submit">
          次へ
        </Button>
      </Form>
    </>
  )
}

export default SenderAddress
type Propss = {
  className?: string
  onClick?: React.MouseEventHandler<Element>
  space?: string
}
export const ListItem: React.FC<Propss> = ({
  className,
  onClick,
  children,
  space,
}) => (
  <ListGroup.Item
    className={`border rounded small text-black ${className} ${space}`}
    onClick={onClick}
    style={{
      minWidth: '240px',
      height: '116px',
      cursor: 'pointer',
    }}
  >
    {children}
  </ListGroup.Item>
)

export const BadgePrimary: React.FC = ({ children }) => (
  <div className="border border-primary px-3 d-inline-block tiny text-primary fw-bold mb-1">
    {children}
  </div>
)
