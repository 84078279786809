import React from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import LatLng from '../types/latLng'

type Props = {
  position: LatLng
  onDragEnd: (e: google.maps.MapMouseEvent) => void
}

const Map: React.FC<Props> = ({ position, onDragEnd }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAP_API_KEY,
  })

  return isLoaded ? (
    <>
      <div className="mb-2">ピンをドラッグして、位置を調整できます</div>
      <GoogleMap mapContainerStyle={containerStyle} center={position} zoom={18}>
        <Marker position={position} onDragEnd={onDragEnd} draggable />
      </GoogleMap>
    </>
  ) : (
    <></>
  )
}

const containerStyle: React.CSSProperties = {
  maxWidth: '626px',
  height: '240px',
}

// export default React.memo(Map)
export default Map
