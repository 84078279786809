import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Asia/Tokyo')
import React, { useEffect, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { useDeliveryDistance } from '../../hooks/deliveryDistance'
import { Order, paymentMethodText } from '../../types/order'
import { PageName } from './CreateOrder'
import IconAngleRight from '../../images/icon_angle-right.svg'
import axios from 'axios'
import { useDeliveryFee } from '../../hooks/deliveryFee'

type Props = {
  order: Order
  setOrder: React.Dispatch<React.SetStateAction<Order>>
  requestUrl: string
  redirectUrl: string
  setPageName: React.Dispatch<React.SetStateAction<PageName>>
  organizationId: string
  hideDeliveryFee: boolean
  canSkipOffer: boolean
}

const Confirmation: React.FC<Props> = ({
  order,
  setOrder,
  requestUrl,
  redirectUrl,
  setPageName,
  organizationId,
  hideDeliveryFee,
  canSkipOffer
}) => {
  const [{ distance, maxDistance }, calcDistance] = useDeliveryDistance()
  const [deliveryFee, calcDeliveryFee] = useDeliveryFee()
  const [errorMessages, setErrorMessages] = useState([])
  const [isOverLimitOfDistance, setIsOverLimitOfDistance] = useState<boolean>()
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (!distance || !maxDistance) {
      return
    }
    setOrder({ ...order, distance: distance, delivery_fee: undefined })

    if (maxDistance < distance) {
      setIsOverLimitOfDistance(true)
      return
    }
    setIsOverLimitOfDistance(false)
    calcDeliveryFee({
      distance,
      token: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content'),
      organizationId,
    })
  }, [distance, maxDistance])

  useEffect(() => {
    setOrder({ ...order, delivery_fee: deliveryFee })
  }, [deliveryFee])

  useEffect(() => {
    calcDistance({
      geolocation: {
        senderLat: order.sender_lat,
        senderLng: order.sender_lng,
        receiverLat: order.receiver_lat,
        receiverLng: order.receiver_lng,
      },
      token: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute('content'),
      organizationId,
      senderAddress: {
        senderZipcode: order.sender_zipcode,
        senderState: order.sender_state,
        senderCity: order.sender_city,
        senderHouseNumber: order.sender_house_number,
        senderBuildingName: order.sender_building_name,
      },
      receiverAddress: {
        receiverZipcode: order.receiver_zipcode,
        receiverState: order.receiver_state,
        receiverCity: order.receiver_city,
        receiverHouseNumber: order.receiver_house_number,
        receiverBuildingName: order.receiver_building_name,
      },
    })
  }, [
    order.sender_lat,
    order.sender_lng,
    order.receiver_lat,
    order.receiver_lng,
  ])

  const createOrder = () => {
    setLoading(true)

    fetch(requestUrl, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ order: { ...order } }),
    }).then((result) => {
      if (result.status === 201 || result.status === 204) {
        window.location.href = redirectUrl
      } else {
        result.json().then((body: string[]) => {
          console.log(body)
          setErrorMessages(body)
          setLoading(false)
        })
      }
    })
  }

  const updateDefaultCard = async () => {
    try {
      const response = await axios.patch(
        `/organizations/${order.card.organization_id}/cards/${order.card.id}/default`,
        {},
        {
          headers: {
            'X-CSRF-Token': document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute('content'),
            'Content-Type': 'application/json',
          },
        }
      )
      console.log('update default card.', response.status)
      return response
    } catch (e) {
      console.log(e)
    }
  }

  const canSubmit: boolean =
    order.distance !== undefined && order.delivery_fee !== undefined

  const canFix: boolean =
    loading == true
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <a
            href={''}
            onClick={(e) => {
              e.preventDefault()
              setPageName('SenderAddress')
            }}
          >
            <div className="d-flex align-items-center">
              {/* <div className="me-2">
                <img src={senderIconUrl} />
              </div> */}
              <div className="my-1">
                <div>集荷お伺い先</div>
              </div>
            </div>
          </a>
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <img src={IconAngleRight} />
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <a
            href={''}
            onClick={(e) => {
              e.preventDefault()
              setPageName('ReceiverAddress')
            }}
          >
            <div className="d-flex align-items-center">
              {/* <div className="me-2">
                <img src={receiverIconUrl} />
              </div> */}
              <div className="my-1">
                <div>お届け先</div>
              </div>
            </div>
          </a>
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <img src={IconAngleRight} />
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <a
            href={''}
            onClick={(e) => {
              e.preventDefault()
              setPageName('Item')
            }}
          >
            <div className="my-1">お荷物情報</div>
          </a>
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <img src={IconAngleRight} />
        </div>
        <div className="me-5 pb-5 border-bottom border-primary border-4">
          <a
            href={''}
            onClick={(e) => {
              e.preventDefault()
            }}
          >
            <div className="my-1 text-primary">確認</div>
          </a>
        </div>
      </div>
      <div className="mb-6 border-top"></div>
      <Form
        onSubmit={(event) => {
          event.preventDefault()
          event.stopPropagation()
          if (order.payment_method === 'card') {
            updateDefaultCard()
          }
          createOrder()
        }}
      >
        <div>
          {errorMessages?.map((errorMessage, idx) => (
            <p key={`error-message-${idx}`} className={'text-danger'}>
              {errorMessage}
            </p>
          ))}
        </div>
        <Form.Group className="mb-5">
          <Form.Label>集荷先</Form.Label>
          <h6>〒{order.sender_zipcode}</h6>
          <h6>
            {order.sender_state +
              order.sender_city +
              order.sender_house_number +
              order.sender_building_name}
          </h6>
          <h6>{order.sender_name}</h6>
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>お届け先</Form.Label>
          <h6>〒{order.receiver_zipcode}</h6>
          <h6>
            {order.receiver_state +
              order.receiver_city +
              order.receiver_house_number +
              order.receiver_building_name}
          </h6>
          <h6>{order.receiver_name}</h6>
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>お荷物の内容</Form.Label>
          <h6>{order.products[0].name}</h6>
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>集荷希望時間</Form.Label>
          <h6>{dayjs(order.pickup_at).tz().format('YYYY/MM/DD HH:mm 頃')}</h6>
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>配送距離</Form.Label>
          {order.distance === undefined ? (
            <></>
          ) : (
            <h6>{Math.ceil((order.distance / 1000) * 10) / 10}km </h6>
          )}
          {isOverLimitOfDistance && (
            <p className="text-danger">配送可能距離を超えています</p>
          )}
        </Form.Group>
        {!hideDeliveryFee && (
          <Form.Group className="mb-5">
            <Form.Label>配送料金</Form.Label>
            <h6>
              {order.delivery_fee === undefined ? '- ' : order.delivery_fee}
              円（税込）
            </h6>
          </Form.Group>
        )}
        {canSkipOffer && (
          <Form.Group className="mb-5">
            <Form.Label>置き配希望</Form.Label>
            <h6>{order.contactless ? "希望します" : "希望しない"}</h6>
          </Form.Group>
        )}
        <Form.Group className="mb-5">
          <Form.Label>コメント</Form.Label>
          <h6>{order.comment}</h6>
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Label>お支払い方法</Form.Label>
          <h6>{paymentMethodText(order.payment_method)}</h6>
          {order.payment_method === 'card' && (
            <div>
              <h6>
                **** **** **** {order.card.last4} {order.card.exp_month}/
                {order.card.exp_year} {order.card.name}
              </h6>
            </div>
          )}
        </Form.Group>
        <Form.Group className="mb-5">
          <Button
            variant="light"
            type="button"
            disabled={canFix}
            onClick={(e) => {
              e.preventDefault()
              setPageName('SenderAddress')
            }}
            className="me-2"
          >
            修正する
          </Button>
          { loading ? (
            <Button variant="secondary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">読み込み中...</span>
            </Button>
          ) : (
            <Button variant="secondary" type="submit" disabled={!canSubmit}>
              配送を依頼する
            </Button>
          )}
        </Form.Group>
        {/* NOTE: マッチングの機能は使わないのでコメントアウト  */}
        {/* <Form.Group className="tiny"> */}
        {/* 配達員が見つからない場合自動キャンセルとなります。 */}
        {/* <br /> */}
        {/* 配送依頼一覧画面より「オファー状況」をご確認ください。 */}
        {/* </Form.Group> */}
      </Form>
    </>
  )
}

export default Confirmation
