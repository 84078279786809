import React from 'react'
import { Dropdown } from 'react-bootstrap'
import axios from 'axios'

type Organization = { id: string; name: string }

type Props = {
  organizations: Organization[]
  currentOrganizationId?: string
}

const SelectOrganization: React.FC<Props> = ({
  organizations,
  currentOrganizationId,
}) => {
  const currentOrganization = organizations.find(
    (organization) => organization.id === currentOrganizationId
  )
  return (
    <Dropdown
      navbar
      align="start"
      onSelect={(eventKey) => {
        axios
          .patch(
            `/users/organizations/${eventKey}`,
            {},
            {
              headers: {
                'X-CSRF-Token': document
                  .querySelector('meta[name="csrf-token"]')
                  .getAttribute('content'),
                'Content-Type': 'application/json',
              },
            }
          )
          .then((response) => {
            console.log('update users organization.', response.status)
          })
          .catch((error) => {
            console.log(error)
          })
        location.href = `/organizations/${eventKey}`
      }}
    >
      <Dropdown.Toggle
        variant="secondary"
        className="text-start p-0 w-100 fw-bold"
        split
      >
        {currentOrganization?.name || '組織を選択'}
      </Dropdown.Toggle>

      <Dropdown.Menu variant="dark">
        {organizations.map((organization: Organization) => (
          <Dropdown.Item
            key={organization.id}
            eventKey={organization.id}
            href={`/organizations/${organization.id}`}
            className="p-1"
          >
            {organization.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SelectOrganization
