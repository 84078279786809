import { Card } from './card'

export type Order = {
  amount: number
  comment: string
  delivery_fee: number | undefined
  distance: number | undefined
  payment_method: PaymentMethod
  pickup_at: string | undefined
  products: Package[]
  receiver_building_name: string
  receiver_city: string
  receiver_email: string
  receiver_house_number: string
  receiver_lat: number | undefined
  receiver_lng: number | undefined
  receiver_name: string
  receiver_state: string
  receiver_tel: string
  receiver_zipcode: string
  sender_building_name: string
  sender_city: string
  sender_email: string
  sender_house_number: string
  sender_lat: number | undefined
  sender_lng: number | undefined
  sender_name: string
  sender_state: string
  sender_tel: string
  sender_zipcode: string
  team_id: string
  save_sender_address: boolean
  save_receiver_address: boolean
  save_as_default_sender_address: boolean
  sender_address_id: string | undefined
  receiver_address_id: string | undefined
  organization_id: string
  card: Card | undefined
  contactless: boolean
}

export type Package = {
  name: string
  amount: number
  category: Category
  other_category: string
  weight: number
}

export type Category =
  | '料理'
  | '生花'
  | 'ケーキ'
  | '本'
  | '食材'
  | '書類'
  | '家電'
  | '衣類'
  | 'その他'

export type PaymentMethod = 'invoice' | 'card'

export const paymentMethodText = (paymentMethod: PaymentMethod): string => {
  switch (paymentMethod) {
    case 'invoice':
      return '請求書払い'
    case 'card':
      return 'クレジットカード'
  }
}
