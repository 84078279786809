import React, { useState } from 'react'
import axios, { AxiosError } from 'axios'
import { Button, Card, Form } from 'react-bootstrap'

const CreateApiKey: React.FC<{
  hasApiKeys: boolean
  organizationId: string
  initialTeamName?: string
}> = ({ hasApiKeys, organizationId, initialTeamName }) => {
  const [teamName, setTeamName] = useState(initialTeamName)
  const [buttonDisabled, setButtonDisabled] = useState(hasApiKeys)
  const [errorMessages, setErrorMessages] = useState([])

  const createApiKeys = async () => {
    try {
      setButtonDisabled(true)
      const response = await axios.post(
        `/organizations/${organizationId}/deaas_api_key`,
        { team: { name: teamName ?? '' } },
        {
          headers: {
            'X-CSRF-Token': document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute('content'),
            'Content-Type': 'application/json',
          },
        }
      )
      console.log('create deaas api keys.', response.status)
      location.href = '/'
    } catch (e) {
      const error = e as AxiosError<string[]>
      console.log(error.response.data)
      setErrorMessages(error.response.data)
    } finally {
      setButtonDisabled(false)
    }
  }

  return (
    <Card className="m-6">
      <Card.Body>
        <Card.Title className="text-center">
          <h2>チーム作成</h2>
        </Card.Title>
        {errorMessages?.map((errorMessage, idx) => (
          <p key={`error-message-${idx}`} className={'text-danger'}>
            {errorMessage}
          </p>
        ))}
        <Form>
          <Form.Group className="mb-5">
            <Form.Label>チーム名</Form.Label>
            <Form.Control
              type="text"
              value={teamName ?? ''}
              placeholder="例:営業本部, 渋谷店, 東京支店, 新宿拠点, マーケティングチームなど"
              onChange={(e) => setTeamName(e.target.value)}
            />
          </Form.Group>
          <div className="text-center mb-5">
            <Button
              variant="secondary"
              size="lg"
              style={{ margin: '0 2rem' }}
              disabled={buttonDisabled}
              onClick={() => {
                createApiKeys()
              }}
            >
              サービス利用を開始する
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default CreateApiKey
