import React, { useEffect, useRef, useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import Map from './Map'
import { Address }  from '../types/address'
import { useLocation } from '../hooks/location'
import { ZipAddressResults } from '../types/zipAddress'
import axios from 'axios'

type AddressInput = Address & { is_default: boolean }

type Props = {
  requestUrl: string
  redirectUrl: string
  method: string
  address?: Address
  defaultSenderAddressId?: string
}

const AddressForm: React.FC<Props> = ({
  requestUrl,
  redirectUrl,
  method,
  address,
  defaultSenderAddressId,
}) => {
  const [addressInput, setAddressInput] = useState<AddressInput>({
    id: address?.id ?? '',
    name: address?.name ?? '',
    zipcode: address?.zipcode ?? '',
    state: address?.state ?? '',
    city: address?.city ?? '',
    house_number: address?.house_number ?? '',
    building_name: address?.building_name ?? '',
    email: address?.email ?? '',
    tel: address?.tel ?? '',
    lat: address?.lat ?? 0,
    lng: address?.lng ?? 0,
    is_default:
      !!defaultSenderAddressId && address?.id === defaultSenderAddressId,
  })
  const [error, setError] = useState([])
  const { state, city, house_number, lat, lng } = addressInput
  const [location, getLocation] = useLocation({ lat, lng })
  const hasBeforeChangedLocation = useRef(false)
  const [isZipcodeInputted, setIsZipcodeInputted] = useState(false)

  useEffect(() => {
    if (addressInput.zipcode && addressInput.zipcode.match(/\d{7}/)) {
      const fetchData = async () => {
        try {
          const result = await axios.get<ZipAddressResults>(
            `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${addressInput.zipcode}`
          );

          if (result.data.results) {
            const resultZipAddress = result.data.results[0]
            const resultData = {
              state: resultZipAddress.address1,
              city: resultZipAddress.address2 + resultZipAddress.address3,
              house_number: isZipcodeInputted ? '' : address?.house_number,
              building_name: isZipcodeInputted ? '' : address?.building_name,
              email: isZipcodeInputted ? '' : address?.email,
              tel: isZipcodeInputted ? '' : address?.tel,
              name: isZipcodeInputted ? '' : address?.name,
            }
            setIsZipcodeInputted(false)
            setAddressInput({ ...addressInput, ...resultData })
          } else {
            alert('郵便番号に該当する住所が見つかりませんでした。\n郵便番号を使用する場合は再度入力してください。')
            const resetData = {
              state: '',
              city: '',
              house_number: '',
              building_name: '',
              email: '',
              tel: '',
              name: '',
            }
            setAddressInput({ ...addressInput, ...resetData })
          }
          console.log('zipcode.:', result.status);
        } catch (e) {
          console.log(e)
        }
      };
      fetchData()
    }
  }, [addressInput.zipcode])

  useEffect(() => {
    if (!lat && !lng) {
      return
    }

    // NOTE: 編集する住所に既に緯度経度が保存されているかチェック
    hasBeforeChangedLocation.current = true
  }, [])

  useEffect(() => {
    if (!location.lat && !location.lng) {
      return
    }

    setAddressInput({ ...addressInput, ...location })
  }, [location])

  useEffect(() => {
    // NOTE: 既に緯度経度が保存されている場合は再取得しない
    if (hasBeforeChangedLocation.current) {
      // NOTE: 保存済みの緯度経度がある場合でも、住所編集時に緯度経度を再取得できるようにする
      hasBeforeChangedLocation.current = false

      return
    }

    if (!state || !city || !house_number) {
      return
    }

    const address = state + city + house_number
    getLocation(address)

    const cleanup = () => {}
    return () => {
      cleanup()
    }
  }, [state, city, house_number])

  const createAddress = () => {
    const { id, ...addressParams } = addressInput
    fetch(requestUrl, {
      method: method,
      headers: {
        'X-CSRF-Token': document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute('content'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({ address: { ...addressParams } }),
    }).then((result) => {
      if (result.status === 201 || result.status === 204) {
        window.location.href = redirectUrl
      } else {
        result.json().then((body) => {
          console.log(body)
          setError(body)
        })
      }
    })
  }

  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault()
        event.stopPropagation()
        createAddress()
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
      }}
    >
      {error && error.map((message) => <p key={message}>{message}</p>)}
      <Form.Group className="mb-5">
        <Form.Label>
          郵便番号
          <span className="tiny ms-1 text-gray">(郵便番号は任意です)</span>
        </Form.Label>
        <Form.Control
          minLength={7}
          maxLength={7}
          type="input"
          value={addressInput.zipcode}
          onChange={(event) => {
            setIsZipcodeInputted(true)
            setAddressInput({ ...addressInput, zipcode: event.target.value })
          }}
        />
      </Form.Group>
      <Form.Group className="mb-5">
        <Form.Label>
          都道府県<span className="tiny ms-1 text-danger">(必須)</span>
        </Form.Label>
        <Form.Control
          required
          type="input"
          value={addressInput.state}
          onChange={(event) => {
            setAddressInput({ ...addressInput, state: event.target.value })
          }}
        />
      </Form.Group>
      <Form.Group className="mb-5">
        <Form.Label>
          市区町村<span className="tiny ms-1 text-danger">(必須)</span>
        </Form.Label>
        <Form.Control
          required
          type="input"
          value={addressInput.city}
          onChange={(event) => {
            setAddressInput({ ...addressInput, city: event.target.value })
          }}
        />
      </Form.Group>
      <Form.Group className="mb-5">
        <Form.Label>
          丁目・番地<span className="tiny ms-1 text-danger">(必須)</span>
        </Form.Label>
        <Form.Control
          required
          type="input"
          value={addressInput.house_number}
          onChange={(event) => {
            setAddressInput({
              ...addressInput,
              house_number: event.target.value,
            })
          }}
        />
      </Form.Group>
      <Form.Group className="mb-5">
        <Form.Label>建物名・部屋番号・その他</Form.Label>
        <Form.Control
          type="input"
          value={addressInput.building_name}
          onChange={(event) => {
            setAddressInput({
              ...addressInput,
              building_name: event.target.value,
            })
          }}
        />
      </Form.Group>
      <Form.Group className="mb-5">
        <Form.Label>
          氏名<span className="tiny ms-1 text-danger">(必須)</span>
        </Form.Label>
        <Form.Control
          required
          type="input"
          value={addressInput.name}
          onChange={(event) => {
            setAddressInput({ ...addressInput, name: event.target.value })
          }}
        />
      </Form.Group>
      <Form.Group className="mb-5">
        <Form.Label>
          電話番号<span className="tiny ms-1 text-danger">(必須)</span>
        </Form.Label>
        <Form.Control
          required
          minLength={10}
          maxLength={11}
          type="input"
          value={addressInput.tel}
          onChange={(event) => {
            setAddressInput({ ...addressInput, tel: event.target.value })
          }}
        />
      </Form.Group>
      <Form.Group className="mb-5">
        <Form.Label>email</Form.Label>
        <Form.Control
          type="email"
          value={addressInput.email}
          onChange={(event) => {
            setAddressInput({ ...addressInput, email: event.target.value })
          }}
        />
      </Form.Group>
      {!!lat && !!lng && (
        <div className="mb-5">
          <Map
            onDragEnd={(e: google.maps.MapMouseEvent): void => {
              const lat = e.latLng.lat()
              const lng = e.latLng.lng()
              setAddressInput({ ...addressInput, lat, lng })
            }}
            position={{ lat: addressInput.lat, lng: addressInput.lng }}
          />
        </div>
      )}
      <Form.Group className="mb-5">
        <Form.Check
          type="checkbox"
          label="この住所を集荷お伺い先のデフォルトに設定する"
          name="default-address"
          id="default-sender-address"
          onChange={(event) => {
            setAddressInput({
              ...addressInput,
              is_default: event.target.checked,
            })
          }}
          checked={!!addressInput.is_default}
        />
      </Form.Group>
      <Button variant="secondary" type="submit">
        {method === 'POST' ? '作成' : '更新'}
      </Button>
    </Form>
  )
}

export default AddressForm
