import React, { useEffect, useState } from 'react'
import { ServiceAreaDeliveryFeeSetting } from '../../types/serviceArea'
import * as Sentry from '@sentry/react'
import '../../config/Sentry'
import { Error } from '../Error'


type Props = {
  noError: boolean
  showError: string
  distance: number
  deliveryFeeSetting: ServiceAreaDeliveryFeeSetting
  showServiceAreaDeliveryFee: boolean
  isFixedFee: boolean
}

const ServiceAreaSearchResult: React.FC<Props> = ({
  noError,
  showError,
  distance,
  deliveryFeeSetting,
  showServiceAreaDeliveryFee,
  isFixedFee
}) => {
  
  const {
    basic_fee,
    first_additional_fee,
    second_additional_fee,
    first_fixed_fee,
    second_fixed_fee,
    third_fixed_fee,
    first_section_distance,
    second_section_distance,
    third_section_distance,
  } = deliveryFeeSetting

  const calcMaxDistance = Math.ceil(third_section_distance * 1000)
  const [isOverLimitOfDistance, setIsOverLimitOfDistance] = useState<boolean>()

  useEffect(() => {
    if (!distance || !calcMaxDistance) {
      return
    }

    if (calcMaxDistance < distance) {
      setIsOverLimitOfDistance(true)
      return
    }
    setIsOverLimitOfDistance(false)
    
  }, [distance, calcMaxDistance])

  const calcAdditionalDeliveryFee = (distance: number) =>
  {
    // NOTE: 配送距離を100m単位で切り上げ、単位をm → kmに変換
    const km = (Math.ceil(distance/100)* 100) / 1000
    
    if (km <= first_section_distance) {
      return 0
    }

    // 追加料金(1段階目)の対象を算出するため、"配送距離かベース距離(1段階目)のどちらか小さい方"からベース距離をマイナスする
    // 100mあたりの追加料金を掛けるときに、0.n kmだと計算しづらいので10倍して整数にする
    let firstAdditionalDistance = 0
  
    if (second_section_distance < km) {
      firstAdditionalDistance = (second_section_distance * 10) - (first_section_distance * 10)
    } else {
      firstAdditionalDistance = (km * 10) - (first_section_distance * 10)
    }
  
    // ベース距離〜ベース距離(1段階目)の区間の追加料金の合計
    const totalFirstAdditionalFee = first_additional_fee * firstAdditionalDistance

    // 配送距離がベース距離(1段階目)以下のときは、ベース距離〜ベース距離(1段階目)の区間の追加料金を返す
    if (km <= second_section_distance) {
      return totalFirstAdditionalFee
    }

    // 追加料金(2段階目)の対象を算出するためベース距離(1段階目)をマイナスする
    // 0.n kmだと計算しづらいので10倍して整数にする
    const secondAdditionalDistance =  (km * 10) - (second_section_distance * 10)
    
    // ベース距離(1段階目)〜ベース距離(2段階目)の区間の追加料金の合計
    const totalSecondAdditionalFee = second_additional_fee * secondAdditionalDistance

    // 追加料金(1段階目)の合計と追加料金(2段階目)の合計を足す
    return  totalFirstAdditionalFee + totalSecondAdditionalFee
  }

  const additionalDeliveryFee = calcAdditionalDeliveryFee(distance)
  const taxMultiplier = 1.1

  const calcDeliveryFee = (additionalDeliveryFee: number)  =>  {
    const deliveryFeeWithoutTax = basic_fee + additionalDeliveryFee;

    return Math.floor((deliveryFeeWithoutTax) * taxMultiplier)
  }

  const calcFixedDeliveryFee = ()  =>  {
    const km = (Math.ceil(distance/100)* 100) / 1000
    let deliveryFeeWithoutTax = 0

    if (km <= first_section_distance) {
      deliveryFeeWithoutTax = first_fixed_fee
    }

    if(first_section_distance < km && km <= second_section_distance) {
      deliveryFeeWithoutTax = second_fixed_fee
    }

    if(second_section_distance < km && km <= third_section_distance) {
      deliveryFeeWithoutTax = third_fixed_fee
    }

    return Math.floor((deliveryFeeWithoutTax) * taxMultiplier)
  }

  let deliveryFee = 0
  const judgeDeliveryFeeStyle = () => {
    if (isFixedFee == false) {
      return deliveryFee = calcDeliveryFee(additionalDeliveryFee)
    } else {
      return deliveryFee = calcFixedDeliveryFee()
    }
  }
  return (
    <Sentry.ErrorBoundary fallback={<Error />}>
      {noError ? (
        <>
          <div className="row">
            {distance === undefined ? (
              <></>
            ) : (
              <>
                <div className="h6 col-3 text-success mb-5">配送距離</div>
                <h6 className="h6 col-3 text-success mb-5">{Math.ceil((distance / 1000) * 10) / 10}km </h6>
              </>
            )}
          </div>
          {isOverLimitOfDistance && (
            <p className="text-danger">配送可能距離を超えています</p>
          )}
          {!isOverLimitOfDistance && distance && (
            <>
              {showServiceAreaDeliveryFee &&(
                <div className="row">
                  {judgeDeliveryFeeStyle() === undefined ? (
                    <></>
                  ) : (
                    <>
                      <div className="h6 col-3 text-success">参考料金</div>
                      <h6 className="col-3 text-success">{deliveryFee}円</h6>
                    </>
                  )}
                </div>
              )}
              <p className="text-success">お届け可能です</p>
            </>
          )}
        </>
      ) : (
        <p className="text-danger">{showError}</p>
      )}
    </Sentry.ErrorBoundary>
  )
}

export default ServiceAreaSearchResult