import React,  { useState } from 'react'
import axios from 'axios'
import { Form, Button, Spinner } from 'react-bootstrap'
import * as Sentry from '@sentry/react'
import '../../config/Sentry'
import { Error } from '../Error'

import { ServiceAreaDefaultAddress, ServiceAreaAddress, ServiceAreaDeliveryFeeSetting } from '../../types/serviceArea'
import ServiceAreaReceiverAddress from './ServiceAreaReceiverAddress'
import ServiceAreaSenderAddress from './ServiceAreaSenderAddress'
import ServiceAreaSearchResult from './ServiceAreaSearchResult'

const SearchServiceArea: React.FC<{
  publicApiKey: string
  defaultSenderAddress: ServiceAreaDefaultAddress
  deliveryFeeSetting: ServiceAreaDeliveryFeeSetting
  showServiceAreaDeliveryFee: boolean
  isFixedFee: boolean
}> = ({
  publicApiKey,
  defaultSenderAddress,
  deliveryFeeSetting,
  showServiceAreaDeliveryFee,
  isFixedFee
}) => {

  const [noError, setNoError] = useState(true);
  const [showError, setShowError] = useState("");
  const [distance, setDistance] = useState(undefined);
  const [loading, setLoading] = useState(false);
  
  const {
    name,
    team_id,
    building_name,
    city,
    house_number,
    state,
    zipcode,
  } = defaultSenderAddress

  const [serviceAreaAddress, setServiceAreaAddress] = useState<ServiceAreaAddress>({
    receiver_building_name: "",
    receiver_city: "",
    receiver_house_number: "",
    receiver_state: "",
    receiver_zipcode: "",
    sender_name: name || "",
    sender_building_name: building_name || "",
    sender_city: city || "",
    sender_house_number: house_number || "",
    sender_state: state || "",
    sender_zipcode: zipcode || "",
    team_id: team_id || "",
  })
  
  const verifyDeliverableArea = async (serviceAreaAddress: ServiceAreaAddress) => {
    try {
      setLoading(true)
      const result = await axios.get<{
        code: string,
        distance: number
      }>(`${process.env.DEAAS_API_URL}/public/v1/delivery-status`, {
        headers: {
          'Content-Type': 'text/plain',
          'X-Api-Key': publicApiKey,
        },
        params: {
          sender_zipcode:
            serviceAreaAddress.sender_zipcode,
          sender_state:
            serviceAreaAddress.sender_state,
          sender_city:
            serviceAreaAddress.sender_city,
          sender_address1:
            serviceAreaAddress.sender_house_number,
          sender_address2:
            serviceAreaAddress.sender_building_name,
          receiver_zipcode:
            serviceAreaAddress.receiver_zipcode,
          receiver_state:
            serviceAreaAddress.receiver_state,
          receiver_city:
            serviceAreaAddress.receiver_city,
          receiver_address1:
            serviceAreaAddress.receiver_house_number,
          receiver_address2:
            serviceAreaAddress.receiver_building_name,
          include_distance: true
        },
      })
      console.log('service area.', result.status)
      return result
    } catch (e) {
      console.log(e)
    }
  }
  const transitionPageWithVerifyArea = async () => {
    const result = await verifyDeliverableArea(serviceAreaAddress)
    setDistance(result.data.distance)
    setNoError(true)
    setLoading(false)
    if (result.data.code !== 'OK') {
        setShowError(errorMessages[result.data.code])
        setNoError(false)
    }
  }

  const errorMessages = {
    // ↓配送上限距離以上だと出る
    A0030: '配送可能距離を超えています',
    A0050:
      '間違った郵便番号や存在しない地名などにより、指定のお届け先住所で位置が取得できませんでした。',
  }

  return (
    <Sentry.ErrorBoundary fallback={<Error />}>
      <div className="h6 mb-5">
        お届けできるか確認をする
      </div>
      <div className="mb-4 border-top" />
      <Form
        id="searchServiceArea"
        onSubmit={(event) => {
          transitionPageWithVerifyArea()
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        <div className="h6 mb-5">配送元 : {serviceAreaAddress.sender_name}</div>
          <ServiceAreaSenderAddress
            serviceAreaAddress={serviceAreaAddress}
            setServiceAreaAddress={setServiceAreaAddress}
          />
        <div className="h6 mb-5">お届け先</div>
        <ServiceAreaReceiverAddress
          serviceAreaAddress={serviceAreaAddress}
          setServiceAreaAddress={setServiceAreaAddress}
        />
        <div className="h6 col-3 mb-5">確認結果</div>
        <ServiceAreaSearchResult
            noError={noError}
            showError={showError}
            distance={distance}
            deliveryFeeSetting={deliveryFeeSetting}
            showServiceAreaDeliveryFee={showServiceAreaDeliveryFee}
            isFixedFee={isFixedFee}
        />
        <div className="d-grid gap-1 col-4">
          { loading ? (
            <Button variant="secondary" disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              <span className="visually-hidden">読み込み中...</span>
            </Button>
          ) : (
            <Button variant="secondary" type="submit">
              結果を見る
            </Button>
          )}
        </div>
      </Form>
    </Sentry.ErrorBoundary>
  )
}

export default SearchServiceArea
