import axios from 'axios'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.tz.setDefault('Asia/Tokyo')
dayjs.extend(isSameOrBefore);
import range from 'just-range'
import React, { useEffect, useState } from 'react'
import {
  Col,
  Form,
  Row,
  Button,
  InputGroup,
  Modal,
  ListGroup,
} from 'react-bootstrap'
import { Category, Order, paymentMethodText } from '../../types/order'
import PickupFromTo from '../../types/pickupFromTo'
import { PageName } from './CreateOrder'
import IconAngleRight from '../../images/icon_angle-right.svg'
import PayjpForm from '../PayjpForm'
import { Card } from '../../types/card'
import { ListItem } from './SenderAddress'

type Props = {
  pickupFromTo: PickupFromTo
  order: Order
  setOrder: React.Dispatch<React.SetStateAction<Order>>
  setPageName: React.Dispatch<React.SetStateAction<PageName>>
  publicApiKey: string
  categories: Category[]
  cards: Card[]
  setCards: React.Dispatch<React.SetStateAction<Card[]>>
  lastUsedCard: Card | undefined
  isCardFormVisible: boolean
  canPayByInvoice: boolean
  canSkipOffer: boolean
}

const Item: React.FC<Props> = ({
  pickupFromTo,
  order,
  setOrder,
  setPageName,
  publicApiKey,
  categories,
  cards,
  setCards,
  lastUsedCard,
  isCardFormVisible,
  canPayByInvoice,
  canSkipOffer,
}) => {
  const [isSelectable, setIsSelectable] = useState(false)
  const [isUndeliverable, setIsUndeliverable] = useState(false)
  const [earliestPickUpAt, setEarliestPickUpAt] = useState<
    dayjs.Dayjs | undefined
  >(undefined)
  const [pickupHour, setPickupHour] = useState<number | undefined>(undefined)
  const [pickupMinute, setPickupMinute] = useState<number | undefined>(
    undefined
  )
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)
  const [payjpErrorMessage, setPayjpErrorMessage] = useState<string>(null)
  const [checkWeightError, setCheckWeightError] = useState(false)

  const interval = 5
  const coefficient = 1000 * 60 * interval

  useEffect(() => {
    if (!earliestPickUpAt) {
      let unmounted = false
      const f = async () => {
        const requiredMinutes = await getEstimatedPickupMinutes()
        let _pickupAt = dayjs().add(requiredMinutes, 'minute')
        // NOTE: 5分毎のminuteに切り上げ
        // TODO: anyCoと同じ計算方法にしたほうがいいかも
        _pickupAt = dayjs(
          new Date(
            Math.ceil(_pickupAt.toDate().getTime() / coefficient) * coefficient
          )
        )

        setEarliestPickUpAt(_pickupAt)

        // NOTE: 確認画面から戻ってきた場合に、
        //       選択済みの時刻が最短の集荷時間より遅い時間であれば
        //       選択済みの時刻を優先する
        if (!!order.pickup_at && dayjs(order.pickup_at) > _pickupAt) {
          setPickupHour(dayjs(order.pickup_at).hour())
          setPickupMinute(dayjs(order.pickup_at).minute())
        } else {
          setPickupHour(_pickupAt.hour())
          setPickupMinute(_pickupAt.minute())
        }
      }
      f()
      const cleanup = () => {
        unmounted = true
      }
      return cleanup()
    }
  }, [earliestPickUpAt])

  useEffect(() => {
    if (pickupHour !== undefined && pickupMinute !== undefined) {
      const pickupAt = dayjs()
        .hour(pickupHour)
        .minute(pickupMinute)
        .tz()
        .format()
      setOrder({ ...order, pickup_at: pickupAt })

      if(dayjs(pickupAt).isBefore(dayjs().hour(pickupFromTo.start_hour).minute(pickupFromTo.start_minute))) {
        setPickupHour(pickupFromTo.start_hour)
        setPickupMinute(pickupFromTo.start_minute)
      }

      if(dayjs(pickupAt).isSameOrBefore(dayjs().hour(pickupFromTo.end_hour).minute(pickupFromTo.end_minute))) {
        setIsSelectable(true)
        setIsUndeliverable(false)
      } else {
        setIsSelectable(false)
        alert("集荷希望時間が21時以降になるため依頼できません")
        setIsUndeliverable(true)
      }
    }
  }, [pickupHour, pickupMinute])

  useEffect(() => {
    const isDisabled =
      (order.payment_method === 'invoice' ? false : order.card ? false : true) || isUndeliverable || checkWeightError
    setIsSubmitButtonDisabled(isDisabled)
  }, [order.payment_method, order.card, isUndeliverable, checkWeightError])

  useEffect(() => {
    if (lastUsedCard && !order.card) {
      setOrder({ ...order, card: lastUsedCard })
    }
  }, [])

  const getEstimatedPickupMinutes = async () => {
    try {
      const result = await axios.get<{
        code: string
        estimated_pickup_minutes: number
      }>(`${process.env.DEAAS_API_URL}/public/v1/geocoded-delivery-status`, {
        headers: {
          'Content-Type': 'text/plain',
          'X-Api-Key': publicApiKey,
        },
        params: {
          sender_address:
            order.sender_zipcode +
            ' ' +
            order.sender_state +
            order.sender_city +
            order.sender_house_number +
            ' ' +
            order.sender_building_name,
          sender_lat: order.sender_lat.toString(),
          sender_lng: order.sender_lng.toString(),
          receiver_address:
            order.receiver_zipcode +
            ' ' +
            order.receiver_state +
            order.receiver_city +
            order.receiver_house_number +
            ' ' +
            order.receiver_building_name,
          receiver_lat: order.receiver_lat.toString(),
          receiver_lng: order.receiver_lng.toString(),
        },
      })
      console.log('pickup minutes.', result.status)
      return result.data.estimated_pickup_minutes
    } catch (e) {
      console.log(e)
    }
  }
  const cardList = lastUsedCard ? [lastUsedCard, ...cards.filter((c) => c.id !== lastUsedCard.id)] : cards

  const weightErrorMessage = "1〜5kgまでの半角数字を入力してください"
  const checkNumber = (value) => {
    if (value.match(/^[1-5]$/)){
      setCheckWeightError(false)
    } else {
      setCheckWeightError(true)
    }
  }
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <a
            href={''}
            onClick={(e) => {
              e.preventDefault()
              setPageName('SenderAddress')
            }}
          >
            <div className="d-flex align-items-center">
              {/* <div className="me-2">
                <img src={senderIconUrl} />
              </div> */}
              <div className="my-1">
                <div>集荷お伺い先</div>
              </div>
            </div>
          </a>
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <img src={IconAngleRight} />
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <a
            href={''}
            onClick={(e) => {
              e.preventDefault()
              setPageName('ReceiverAddress')
            }}
          >
            <div className="d-flex align-items-center">
              {/* <div className="me-2">
                <img src={receiverIconUrl} />
              </div> */}
              <div className="my-1">
                <div>お届け先</div>
              </div>
            </div>
          </a>
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <img src={IconAngleRight} />
        </div>
        <div className="me-5 pb-5 border-bottom border-primary border-4">
          <a
            href={''}
            onClick={(e) => {
              e.preventDefault()
            }}
          >
            <div className="my-1 text-primary">お荷物情報</div>
          </a>
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <img src={IconAngleRight} />
        </div>
        <div className="me-5 pb-5 border-bottom border-white border-4">
          <button
            form="item"
            type="submit"
            className="btn text-secondary p-0"
            disabled={isSubmitButtonDisabled}
          >
            <div className="my-1">確認</div>
          </button>
        </div>
      </div>
      <div className="mb-6 border-top"></div>
      <Form
        id="item"
        onSubmit={(event) => {
          setPageName('Confirmation')
          event.preventDefault()
          event.stopPropagation()
        }}
      >
        <Form.Group className="mb-5">
          <Row>
            <Col md={12}>
              <Form.Label className="required">集荷希望時間</Form.Label>
              <span className="tiny">
                &nbsp;ご注文状況、交通事情に応じて、前後する可能性がございます
              </span>
            </Col>
            <Col md={5}>
              <Form.Select
                disabled={!isSelectable}
                value={pickupHour}
                onChange={(event) => {
                  const value = parseInt(event.currentTarget.value)
                  setPickupHour(value)

                  if (
                    earliestPickUpAt.hour() === value &&
                    earliestPickUpAt.minute() > pickupMinute
                  ) {
                    // NOTE: hourを最短の配送時刻に戻したときに、
                    //       minuteが選択できない時刻だった場合、最短のminuteに戻す
                    setPickupMinute(earliestPickUpAt.minute())
                  }
                  if (
                    pickupFromTo.end_hour === value &&
                    pickupFromTo.end_minute < pickupMinute
                  ) {
                    // NOTE: hourを最終受付時刻にしたときに、
                    //       minuteが最終受付時刻以降だった場合、最終の受付時刻に戻す
                    setPickupMinute(pickupFromTo.end_minute)
                  }
                  if (
                    pickupFromTo.start_hour === value &&
                    pickupFromTo.start_minute > pickupMinute &&
                    dayjs(earliestPickUpAt).isBefore(dayjs().hour(pickupFromTo.start_hour).minute(pickupFromTo.start_minute))
                  ) {
                    // NOTE: hourを開始受付時刻にしたときに、
                    //       minuteが開始受付時刻以前で、
                    //       算出した最短の希望時刻が11:15を越さないとき最短のminuteに戻す
                    setPickupMinute(pickupFromTo.start_minute)
                  }
                }}
              >
                {range(pickupFromTo.start_hour, pickupFromTo.end_hour + 1).map(
                  (hour) => {
                    return (
                      <option
                        value={hour}
                        key={hour}
                        disabled={
                          dayjs().hour() > hour ||
                          (earliestPickUpAt && earliestPickUpAt.hour() > hour)
                        }
                      >
                        {hour}
                      </option>
                    )
                  }
                )}
              </Form.Select>
            </Col>
            <Col
              md={2}
              className="d-flex align-items-center justify-content-center"
            >
              :
            </Col>
            <Col md={5}>
              <Form.Select
                disabled={!isSelectable}
                value={pickupMinute}
                onChange={(event) => {
                  const value = parseInt(event.currentTarget.value)
                  setPickupMinute(value)
                }}
              >
                {[...Array(60 / interval)].map((_, i) => {
                  const value = i * interval
                  return (
                    <option
                      value={value}
                      key={i}
                      disabled={
                        (earliestPickUpAt &&
                          earliestPickUpAt.hour() === pickupHour &&
                          earliestPickUpAt.minute() > value) ||
                        (pickupFromTo.end_hour === pickupHour &&
                          pickupFromTo.end_minute < value) ||
                        (pickupFromTo.start_hour === pickupHour &&
                          pickupFromTo.start_minute > value)
                      }
                    >
                      {value}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
            <Col md={12}>
              <span className="tiny">
                お届け時間目安（集荷後）：1.5km以内 約10～15分後
              </span>
            </Col>
            <Col md={12}>
              <span className="tiny">
                集荷希望時刻は11時15分から21時まで選択可
              </span>
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mb-5">
          <Row>
            <Form.Label className="required">種類</Form.Label>
            <Col md={12}>
              <Form.Select
                value={order.products[0].category}
                onChange={(event) => {
                  const product = order.products[0]
                  const value = event.currentTarget.value
                  setOrder({
                    ...order,
                    products: [
                      {
                        amount: product.amount,
                        name:
                          value === 'その他'
                            ? order.products[0].other_category +
                              order.products[0].weight +
                              'kg'
                            : value + order.products[0].weight + 'kg',
                        category: value as Category,
                        other_category: '',
                        weight: product.weight,
                      },
                    ],
                  })
                }}
              >
                {categories.map((name, i) => {
                  return (
                    <option value={name} key={i}>
                      {name}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
        {order.products[0].category === 'その他' && (
          <Form.Group className="mb-5">
            <Row>
              <Col md={12}>
                <Form.Control
                  type="text"
                  value={order.products[0].other_category}
                  placeholder="具体的な内容をご記入ください"
                  onChange={(event) => {
                    const value = event.target.value
                    setOrder({
                      ...order,
                      products: [
                        {
                          ...order.products[0],
                          name: value + order.products[0].weight + 'kg',
                          other_category: value,
                        },
                      ],
                    })
                  }}
                />
              </Col>
            </Row>
          </Form.Group>
        )}
        <Form.Group className="mb-5">
          <Row>
            <Form.Label className="required">重さ</Form.Label>
            <Col md={12}>
              <InputGroup>
                <Form.Control
                  required
                  type="text"
                  value={order.products[0].weight}
                  onChange={(event) => {
                    const value = event.target.value
                    checkNumber(value)
                    setOrder({
                      ...order,
                      products: [
                        {
                          ...order.products[0],
                          name: order.products[0].category + value + 'kg',
                          weight: parseInt(value) || null,
                        },
                      ],
                    })
                  }}
                />
                <InputGroup.Text>kg</InputGroup.Text>
              </InputGroup>
            </Col>
            {checkWeightError && (
            <Row>
              <p className='text-danger'>{weightErrorMessage}</p>
            </Row>
            )}
          </Row>
        </Form.Group>
        {canSkipOffer && (
          <Form.Group className="mb-5">
            <Row>
              <Col md={12}>
                <Form.Label>置き配希望</Form.Label>
                <span className="tiny">
                  &nbsp;※ご希望しない場合は対面でお渡しいたします
                </span>
              </Col>
              <Col md={12}>
              <Form.Check
                type="checkbox"
                label="希望する"
                checked={order.contactless}
                onChange={(event) => {
                  const value = event.target.checked
                  setOrder({
                    ...order,
                    contactless: value,
                  })
                }}
              />
              </Col>
            </Row>
          </Form.Group>
        )}
        <Form.Group className="border-bottom">
          <Row className="mb-6">
            <Form.Label>コメント</Form.Label>
            <Col md={12}>
              <Form.Control
                as="textarea"
                type="text"
                placeholder="受付で「弁当容器」をお渡ししますので、配送依頼先の店舗に届けて欲しいです。"
                value={order.comment}
                onChange={(event) => {
                  setOrder({
                    ...order,
                    comment: event.target.value,
                  })
                }}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className="mt-6 mb-5">
          <Row>
            <Form.Label>お支払い方法</Form.Label>
            <Col md={6}>
              <Form.Check
                type="radio"
                label="クレジットカード"
                name="payment-method"
                id="payment-method-card"
                onChange={(e) =>
                  e.target.checked &&
                  setOrder({ ...order, payment_method: 'card' })
                }
                checked={order.payment_method === 'card'}
              />
            </Col>
            {canPayByInvoice && (
              <Col md={6}>
                <Form.Check
                  type="radio"
                  label={paymentMethodText('invoice')}
                  name="payment-method"
                  id="payment-method-invoice"
                  onChange={(e) =>
                    e.target.checked &&
                    setOrder({ ...order, payment_method: 'invoice' })
                  }
                  checked={order.payment_method === 'invoice'}
                />
              </Col>
            )}
          </Row>
        </Form.Group>
        {order.payment_method === 'card' && (
          <Form.Group className="mb-5">
            {cards.length > 0 ? (
              <ListGroup horizontal className="overflow-auto">
                {cardList.map((card: Card, index) => {
                  return (
                    <ListItem
                      key={card.id}
                      className={card.id === order.card?.id && 'border-primary'}
                      space={index !== 0 && 'ms-5'}
                      onClick={() => setOrder({ ...order, card: card })}
                    >
                      <div>**** **** **** {card.last4}</div>
                      <div>
                        {card.exp_month}/{card.exp_year}
                      </div>
                      <div>{card.name}</div>
                    </ListItem>
                  )
                })}
              </ListGroup>
            ) : (
              <div>
                <span>クレジットカードを登録してください</span>
              </div>
            )}
            <div hidden={!isCardFormVisible}>
              <a
                href={''}
                onClick={(e) => {
                  e.preventDefault()
                  setIsModalVisible(true)
                }}
              >
                + 新しいカードを登録
              </a>
            </div>
          </Form.Group>
        )}
        <Button
          variant="secondary"
          type="submit"
          disabled={isSubmitButtonDisabled}
        >
          次へ
        </Button>
      </Form>
      <Modal
        show={isModalVisible}
        onHide={() => {
          setIsModalVisible(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>新しいカードを登録</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PayjpForm
            organizationId={order.organization_id}
            cards={cards}
            setCards={setCards}
            onSubmit={() => {
              setIsModalVisible(false)
            }}
            errorMessage={payjpErrorMessage}
            setErrorMessage={setPayjpErrorMessage}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Item
