import { useState } from 'react'

export const useDeliveryFee = (): [
  number,
  ({
    distance,
    token,
    organizationId,
  }: {
    distance: number
    token: string
    organizationId: string
  }) => Promise<void>
] => {
  const [deliveryFee, setDeliveryFee] = useState<number>()

  const calcDeliveryFee = async ({
    distance,
    token,
    organizationId,
  }: {
    distance: number
    token: string
    organizationId: string
  }) => {
    const response = await fetch(
      `/organizations/${organizationId}/delivery_fee`,
      {
        method: 'POST',
        headers: {
          'X-CSRF-Token': token,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          distance: distance,
        }),
      }
    )
    const data = await response.json()
    const _deliveryFee = data.delivery_fee
    console.log('deliveryFee', _deliveryFee)
    setDeliveryFee(_deliveryFee)
  }

  return [deliveryFee, calcDeliveryFee]
}
