import { Loader } from '@googlemaps/js-api-loader'
import * as Sentry from '@sentry/react'
import axios from 'axios'
import React, { useState } from 'react'
import '../../config/Sentry'
import { Address } from '../../types/address'
import { Card } from '../../types/card'
import { Category, Order } from '../../types/order'
import PickupFromTo from '../../types/pickupFromTo'
import { Error } from '../Error'
import Confirmation from './Confirmation'
import Item from './Item'
import ReceiverAddress from './ReceiverAddress'
import SenderAddress from './SenderAddress'

const payjpPublicApiKey = process.env.PAYJP_PUBLIC_API_KEY
window.payjp = window.Payjp(payjpPublicApiKey)

// TODO: 開発用のキーを用意してもらう
const loader = new Loader({
  apiKey: process.env.GOOGLE_MAP_API_KEY,
  version: 'weekly',
})

export type PageName =
  | 'SenderAddress'
  | 'ReceiverAddress'
  | 'Item'
  | 'Confirmation'

const CreateOrder: React.FC<{
  addresses: Address[]
  orderParams: Order
  publicApiKey: string
  pickUpFromTo: PickupFromTo
  categories: Category[]
  redirectUrl: string
  requestUrl: string
  initialPageName: PageName
  defaultSenderAddressId: string
  cards: Card[]
  lastUsedCard: Card | undefined
  isCardFormVisible: boolean
  organizationId: string
  canSkipOffer: boolean
  hideDeliveryFee: boolean
  canPayByInvoice: boolean
}> = ({
  publicApiKey,
  addresses,
  orderParams,
  pickUpFromTo,
  categories,
  redirectUrl,
  requestUrl,
  initialPageName,
  defaultSenderAddressId,
  cards,
  lastUsedCard,
  isCardFormVisible,
  organizationId,
  canSkipOffer,
  hideDeliveryFee,
  canPayByInvoice,
}) => {
  const [pageName, setPageName] = useState<PageName>(initialPageName)
  const [order, setOrder] = useState<Order>({
    amount: orderParams.amount || 0,
    comment: orderParams.comment || '',
    delivery_fee: orderParams.delivery_fee || undefined,
    distance: orderParams.distance || undefined,
    payment_method: orderParams.payment_method || 'card',
    pickup_at: undefined,
    products: [
      {
        amount: orderParams.products?.[0].amount || 1,
        name: orderParams.products?.[0].name || '料理1kg',
        category: orderParams.products?.[0].category || '料理',
        other_category: orderParams.products?.[0].other_category || '',
        weight: orderParams.products?.[0].weight || 1,
      },
    ],
    receiver_building_name: orderParams.receiver_building_name || '',
    receiver_city: orderParams.receiver_city || '',
    receiver_email: orderParams.receiver_email || '',
    receiver_house_number: orderParams.receiver_house_number || '',
    receiver_lat: orderParams.receiver_lat || undefined,
    receiver_lng: orderParams.receiver_lng || undefined,
    receiver_name: orderParams.receiver_name || '',
    receiver_state: orderParams.receiver_state || '',
    receiver_tel: orderParams.receiver_tel || '',
    receiver_zipcode: orderParams.receiver_zipcode || '',
    sender_building_name: orderParams.sender_building_name || '',
    sender_city: orderParams.sender_city || '',
    sender_email: orderParams.sender_email || '',
    sender_house_number: orderParams.sender_house_number || '',
    sender_lat: orderParams.sender_lat || undefined,
    sender_lng: orderParams.sender_lng || undefined,
    sender_name: orderParams.sender_name || orderParams.sender_name || '',
    sender_state: orderParams.sender_state || '',
    sender_tel: orderParams.sender_tel || '',
    sender_zipcode: orderParams.sender_zipcode || '',
    team_id: orderParams.team_id,
    save_sender_address: false,
    save_receiver_address: false,
    save_as_default_sender_address: false,
    sender_address_id: orderParams.sender_address_id || undefined,
    receiver_address_id: orderParams.receiver_address_id || undefined,
    organization_id: orderParams.organization_id,
    card: undefined,
    contactless: orderParams.contactless,
  })
  const [_cards, setCards] = useState<Card[] | undefined>(cards)

  // HACK: setStateは非同期のため緯度経度更新直後に呼び出すとエラーになるので引数にしている
  const verifyDeliverableArea = async (order: Order) => {
    try {
      const result = await axios.get<{
        code: string
      }>(`${process.env.DEAAS_API_URL}/public/v1/geocoded-service-area`, {
        headers: {
          'Content-Type': 'text/plain',
          'X-Api-Key': publicApiKey,
        },
        params: {
          sender_address:
            order.sender_zipcode +
            ' ' +
            order.sender_state +
            order.sender_city +
            order.sender_house_number +
            ' ' +
            order.sender_building_name,
          sender_lat: order.sender_lat,
          sender_lng: order.sender_lng,
          receiver_address:
            order.receiver_zipcode +
            ' ' +
            order.receiver_state +
            order.receiver_city +
            order.receiver_house_number +
            ' ' +
            order.receiver_building_name,
          receiver_lat: order.receiver_lat,
          receiver_lng: order.receiver_lng,
        },
      })
      console.log('service area.', result.status)
      return result.data.code
    } catch (e) {
      console.log(e)
    }
  }

  const transitionPageWithVerifyArea = async (pageName: PageName) => {
    const result = await verifyDeliverableArea(order)

    if (result === 'OK') {
      setPageName(pageName)
    } else {
      alert(errorMessages[result])
    }
  }

  const errorMessages = {
    A0010: '配送サービスの受付を一時的に停止しています。',
    A0020: '店舗住所がサービス提供エリア外です。',
    A0030: '配送先住所がサービス提供エリア外です。',
    A0040:
      '間違った郵便番号や存在しない地名などにより、指定の店舗住所で位置が取得できませんでした。',
    A0050:
      '間違った郵便番号や存在しない地名などにより、指定の配送先住所で位置が取得できませんでした。',
    A0070: '配送サービスの受付時間外です。',
  }

  return (
    <Sentry.ErrorBoundary fallback={<Error />}>
      {pageName === 'SenderAddress' && (
        <SenderAddress
          addresses={addresses}
          order={order}
          setOrder={setOrder}
          setPageName={setPageName}
          transitionPageWithVerifyArea={transitionPageWithVerifyArea}
          defaultSenderAddressId={defaultSenderAddressId}
        />
      )}
      {pageName === 'ReceiverAddress' && (
        <ReceiverAddress
          addresses={addresses}
          order={order}
          setOrder={setOrder}
          setPageName={setPageName}
          transitionPageWithVerifyArea={transitionPageWithVerifyArea}
        />
      )}
      {pageName === 'Item' && (
        <Item
          pickupFromTo={pickUpFromTo}
          order={order}
          setOrder={setOrder}
          setPageName={setPageName}
          publicApiKey={publicApiKey}
          categories={categories}
          cards={_cards}
          setCards={setCards}
          lastUsedCard={lastUsedCard}
          isCardFormVisible={isCardFormVisible}
          canPayByInvoice={canPayByInvoice}
          canSkipOffer={canSkipOffer}
        />
      )}
      {pageName === 'Confirmation' && (
        <Confirmation
          order={order}
          setOrder={setOrder}
          redirectUrl={redirectUrl}
          requestUrl={requestUrl}
          setPageName={setPageName}
          organizationId={organizationId}
          hideDeliveryFee={hideDeliveryFee}
          canSkipOffer={canSkipOffer}
        />
      )}
    </Sentry.ErrorBoundary>
  )
}

export default CreateOrder
