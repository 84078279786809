import React, { useRef, useState } from 'react'
import { Overlay, Tooltip } from 'react-bootstrap'
import { FaQuestionCircle } from 'react-icons/fa'

type Props = {
  labelText: string
  toolTipText: string
}

const TooltipLabel: React.FC<Props> = ({ labelText, toolTipText }) => {
  const [isVisible, setIsVisible] = useState(false)
  const target = useRef(null)

  return (
    <>
      <div
        ref={target}
        onClick={() => setIsVisible(!isVisible)}
        className="d-inline-block"
      >
        <span className="align-middle">{labelText}&nbsp;</span>
        <FaQuestionCircle size={10} />
      </div>
      <Overlay target={target.current} show={isVisible} placement="top">
        <Tooltip className="tiny" onClick={() => setIsVisible(!isVisible)}>
          {toolTipText}
        </Tooltip>
      </Overlay>
    </>
  )
}

export default TooltipLabel
