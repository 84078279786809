import React, {  useState } from 'react'
import {
  Modal,
} from 'react-bootstrap'
import PayjpForm from './PayjpForm'

type Props = {
  isCardFormVisible: boolean
  organizationId: string
}

const payjpPublicApiKey = process.env.PAYJP_PUBLIC_API_KEY
window.payjp = window.Payjp(payjpPublicApiKey)

const CreateCard: React.FC<Props> = ({
  organizationId,
  isCardFormVisible,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [payjpErrorMessage, setPayjpErrorMessage] = useState<string>(null)

  return (
    <>
      <div hidden={!isCardFormVisible}>
        <a
          href={''}
          onClick={(e) => {
            e.preventDefault()
            setIsModalVisible(true)
          }}
        >
          + 新しいカードを登録
        </a>
      </div>
      <Modal
        show={isModalVisible}
        onHide={() => {
          setIsModalVisible(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>新しいカードを登録</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PayjpForm
            organizationId={organizationId}
            onSubmit={() => {
              setIsModalVisible(false)
              window.location.reload()
            }}
            errorMessage={payjpErrorMessage}
            setErrorMessage={setPayjpErrorMessage}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CreateCard
