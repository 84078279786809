import React from 'react'
import { Dropdown } from 'react-bootstrap'
import axios from 'axios'

type Team = { id: string; name: string }

type Props = {
  teams?: Team[]
  currentOrganizationId?: string
  currentTeamId?: string
  isCreatable: boolean
}

const SelectTeam: React.FC<Props> = ({
  teams,
  currentOrganizationId,
  currentTeamId,
  isCreatable,
}) => {
  const currentTeam = teams?.find((team) => team.id === currentTeamId)
  const title = () => {
    if (!currentOrganizationId) {
      return '組織を選択してください'
    }

    return currentTeam?.name || 'チームを選択'
  }

  return (
    <Dropdown
      navbar
      align="start"
      onSelect={(eventKey) => {
        axios
          .patch(
            `/users/teams/${eventKey}`,
            {},
            {
              headers: {
                'X-CSRF-Token': document
                  .querySelector('meta[name="csrf-token"]')
                  .getAttribute('content'),
                'Content-Type': 'application/json',
              },
            }
          )
          .then((response) => {
            console.log('update users teams.', response.status)
          })
          .catch((error) => {
            console.log(error)
          })
        location.href = `/organizations/${currentOrganizationId}/teams/${eventKey}`
      }}
    >
      <Dropdown.Toggle
        variant="secondary"
        className="text-start p-0 w-100 fw-bold"
        split
      >
        {title()}
      </Dropdown.Toggle>

      <Dropdown.Menu variant="dark">
        {teams?.map((team: Team) => (
          <Dropdown.Item key={team.id} eventKey={team.id} className="p-1">
            {team.name}
          </Dropdown.Item>
        ))}
        {currentOrganizationId && isCreatable && (
          <Dropdown.Item
            href={`${location.protocol}//${location.host}/organizations/${currentOrganizationId}/teams/new`}
            className="p-1"
          >
            チームを作成
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default SelectTeam
