import { Loader } from '@googlemaps/js-api-loader'
import { useState } from 'react'
import LatLng from '../types/latLng'

const loader = new Loader({
  apiKey: process.env.GOOGLE_MAP_API_KEY,
  version: 'weekly',
})

export const useLocation = (
  initialLocation: LatLng = { lat: 0, lng: 0 }
): [LatLng, (address: string) => Promise<LatLng>] => {
  const [location, setLocation] = useState<LatLng>(initialLocation)
  const getLocation = async (address: string): Promise<LatLng> => {
    await loader.load()
    const geocoder = new google.maps.Geocoder()
    const { results } = await geocoder.geocode(
      {
        address: address,
      },
      (_, status) => {
        if (status !== 'OK') {
          return
        }
      }
    )

    const geoLocation = results?.[0].geometry.location
    const lat = await geoLocation.lat()
    const lng = await geoLocation.lng()
    setLocation({ lat, lng })

    return { lat, lng }
  }

  return [location, getLocation]
}
