import { useState } from 'react'

type Geolocation = {
  senderLat: number
  senderLng: number
  receiverLat: number
  receiverLng: number
}
type SenderAddress = {
  senderZipcode: string
  senderState: string
  senderCity: string
  senderHouseNumber: string
  senderBuildingName: string
}
type ReceiverAddress = {
  receiverZipcode: string
  receiverState: string
  receiverCity: string
  receiverHouseNumber: string
  receiverBuildingName: string
}

export const useDeliveryDistance = (): [
  {
    distance: number | undefined
    maxDistance: number | undefined
  },
  ({
    geolocation,
    token,
    organizationId,
    senderAddress,
    receiverAddress,
  }: {
    geolocation: Geolocation
    token: string
    organizationId: string
    senderAddress: SenderAddress
    receiverAddress: ReceiverAddress
  }) => Promise<void>
] => {
  const [distance, setDistance] = useState<number | undefined>(undefined)
  const [maxDistance, setMaxDistance] = useState<number | undefined>(undefined)

  const calcDistance = async ({
    geolocation,
    token,
    organizationId,
    senderAddress,
    receiverAddress,
  }: {
    geolocation: Geolocation
    token: string
    organizationId: string
    senderAddress: SenderAddress
    receiverAddress: ReceiverAddress
  }) => {
    const { senderLat, senderLng, receiverLat, receiverLng } = geolocation
    const {
      senderZipcode,
      senderState,
      senderCity,
      senderHouseNumber,
      senderBuildingName,
    } = senderAddress
    const {
      receiverZipcode,
      receiverState,
      receiverCity,
      receiverHouseNumber,
      receiverBuildingName,
    } = receiverAddress
    const response = await fetch(`/organizations/${organizationId}/distance`, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        sender_lat: senderLat,
        sender_lng: senderLng,
        receiver_lat: receiverLat,
        receiver_lng: receiverLng,
        sender_zipcode: senderZipcode,
        sender_state: senderState,
        sender_city: senderCity,
        sender_house_number: senderHouseNumber,
        sender_building_name: senderBuildingName,
        receiver_zipcode: receiverZipcode,
        receiver_state: receiverState,
        receiver_city: receiverCity,
        receiver_house_number: receiverHouseNumber,
        receiver_building_name: receiverBuildingName,
      }),
    })
    const data = await response.json()
    const _distance = data.distance
    const _maxDistance = data.max_distance
    console.log('distance', _distance)
    console.log('maxDistance', _maxDistance)
    setDistance(_distance)
    setMaxDistance(_maxDistance)
  }

  return [
    {
      distance: distance,
      maxDistance: maxDistance,
    },
    calcDistance,
  ]
}
