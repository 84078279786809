import React from 'react'
import { Form } from 'react-bootstrap'
import { ServiceAreaAddress } from '../../types/serviceArea'

type Props = {
  serviceAreaAddress: ServiceAreaAddress
  setServiceAreaAddress: React.Dispatch<React.SetStateAction<ServiceAreaAddress>>
}

const ServiceAreaReceiverAddress: React.FC<Props> = ({
  serviceAreaAddress,
  setServiceAreaAddress,
}) => {

  return (
    <>
      <Form.Group className="mb-5">
        <Form.Label>
          郵便番号
          <span className="tiny ms-1 text-gray">(郵便番号は任意です)</span>
        </Form.Label>
        <Form.Control
          minLength={7}
          maxLength={7}
          type="input"
          value={serviceAreaAddress.receiver_zipcode}
          onChange={(event) => {
            const value = parseInt(event.target.value) || ''
            setServiceAreaAddress({ ...serviceAreaAddress, receiver_zipcode: value.toString() })
          }}
        />
      </Form.Group>
      <Form.Group className="mb-5">
        <Form.Label>
          都道府県<span className="tiny ms-1 text-danger">(必須)</span>
        </Form.Label>
        <Form.Control
          required
          type="input"
          value={serviceAreaAddress.receiver_state}
          onChange={(event) => {
            setServiceAreaAddress({ ...serviceAreaAddress, receiver_state: event.target.value })
          }}
        />
      </Form.Group>
      <Form.Group className="mb-5">
        <Form.Label>
          市区町村<span className="tiny ms-1 text-danger">(必須)</span>
        </Form.Label>
        <Form.Control
          required
          type="input"
          value={serviceAreaAddress.receiver_city}
          onChange={(event) => {
            setServiceAreaAddress({ ...serviceAreaAddress, receiver_city: event.target.value })
          }}
        />
      </Form.Group>
      <Form.Group className="mb-5">
        <Form.Label>
          丁目・番地<span className="tiny ms-1 text-danger">(必須)</span>
        </Form.Label>
        <Form.Control
          required
          type="input"
          value={serviceAreaAddress.receiver_house_number}
          onChange={(event) => {
            setServiceAreaAddress({ ...serviceAreaAddress, receiver_house_number: event.target.value })
          }}
        />
      </Form.Group>
      <Form.Group className="mb-5">
        <Form.Label>建物名・部屋番号・その他</Form.Label>
        <Form.Control
          type="input"
          value={serviceAreaAddress.receiver_building_name}
          onChange={(event) => {
            setServiceAreaAddress({ ...serviceAreaAddress, receiver_building_name: event.target.value })
          }}
        />
      </Form.Group>
    </>
  )
}

export default ServiceAreaReceiverAddress
