import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { Card } from '../types/card'
type Props = {
  organizationId: string
  cards?: Card[]
  setCards?: React.Dispatch<React.SetStateAction<Card[]>>
  onSubmit: () => void
  errorMessage: string
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
}

const PayjpForm: React.FC<Props> = ({
  organizationId,
  cards,
  setCards,
  onSubmit,
  errorMessage,
  setErrorMessage,
}) => {
  let elements = window.payjp.elements()
  let cardElementStyle = {
    base: {},
    invalid: {
      color: 'red',
    },
  }
  let cardElement = elements.create('card', { style: cardElementStyle })

  useEffect(() => {
    cardElement.mount('#payjpFormContainer')
    console.log('init payjp form.')
  }, [cardElement])

  const createToken = async (params: any) => {
    try {
      const result = await window.payjp.createToken(cardElement, {
        card: params,
      })
      if (result.id) {
        // console.log(result)
        const response = await createCard(result.id)
        if (!response) {
          return
        }

      if(setCards && cards) {
        setCards([response.data.card, ...cards])
      }
        onSubmit()
      } else {
        console.log(result.error.status)
        setErrorMessage(result.error.message)
      }
    } catch (e) {
      console.log(e)
      setErrorMessage('不明なエラーが発生しました。')
    }
  }

  const createCard = async (token: string) => {
    try {
      setErrorMessage(null)
      const response = await axios.post<{ card: Card }>(
        `/organizations/${organizationId}/cards`,
        { card: { token } },
        {
          headers: {
            'X-CSRF-Token': document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute('content'),
            'Content-Type': 'application/json',
          },
        }
      )
      console.log('create card.', response.status)
      return response
    } catch (e) {
      console.log(e)
      if (e?.response?.data?.error_message) {
        setErrorMessage(e.response.data.error_message)
      }
      cardElement = elements.create('card', { style: cardElementStyle })
    }
  }

  // NOTE: マウントされたpayjpのフォームは再レンダリングされるとエラーになるため、
  //       stateが必要な場合は別のコンポーネントで管理する。
  return (
    <>
      {errorMessage && (
        <Alert variant="danger" className="mb-2">
          {errorMessage}
        </Alert>
      )}
      <div id="payjpFormContainer" className="payjp-form-container"></div>
      <p className="text-danger form-text mb-5">
        ※カード登録画面の入力の際「CVC（カード裏面等に記載の暗証番号）」を入力しないと登録ボタンが押せません。わかりづらくなっておりますので、ご注意ください。
      </p>
      <OptionalForm createToken={createToken} />
    </>
  )
}

const OptionalForm: React.FC<{
  createToken: (params: any) => void
}> = ({ createToken }) => {
  const [name, setName] = useState('')
  return (
    <Form
      onSubmit={(event) => {
        event.preventDefault()
        event.stopPropagation()
        createToken({ name })
      }}
    >
      <Form.Group className="mb-5">
        <Form.Control
          required
          type="input"
          placeholder="名前"
          value={name}
          onChange={(event) => {
            setName(event.target.value)
          }}
        />
      </Form.Group>
      <Button variant="secondary" type="submit">
        登録
      </Button>
    </Form>
  )
}

export default PayjpForm
