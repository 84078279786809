import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { ServiceAreaAddress } from '../../types/serviceArea'

type Props = {
  serviceAreaAddress: ServiceAreaAddress
  setServiceAreaAddress: React.Dispatch<React.SetStateAction<ServiceAreaAddress>>
}

const ServiceAreaSenderAddress: React.FC<Props> = ({
  serviceAreaAddress,
}) => {

  return (
    <>
      <Form.Group className="mb-5">
        <h6>{"〒" + serviceAreaAddress.sender_zipcode}</h6>
        <h6>{
            serviceAreaAddress.sender_state + 
            serviceAreaAddress.sender_city  +
            serviceAreaAddress.sender_house_number
        }</h6>
        <h6>{serviceAreaAddress.sender_building_name}</h6>
      </Form.Group>
    </>
  )
}

export default ServiceAreaSenderAddress